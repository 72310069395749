<template>
<span>
  <template v-if="user && !user.emailVerified">

        <v-alert
          outline
          color="error"
          icon="info"
          :value="true"
          v-if="showVerification"
          >Sorry, your email ({{ user.email }}) is not verified.
          <v-btn text small @click="sendVerificationEmail" color="blue"
            >Send verification email</v-btn
          >
        </v-alert>
        <v-alert outlined color="info" :value="true" icon="info" v-else>
          Verification email sent. Please check your email and click refresh
          link to verify your account. Once verified please refresh
          <v-btn text icon @click.prevent="refresh">
            <v-icon large color="accent">refresh</v-icon>
          </v-btn>
        </v-alert>

  </template>

  </span>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showVerification: true
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      user:'user'
    })
  },
   methods: {
    sendVerificationEmail() {
        this.$store.dispatch('sendVerificationEmail')
    },
    refresh() {
      this.$router.go(this.$router.currentRoute)
    }
  }

}
</script>
