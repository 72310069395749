<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="10"> <h2>Payment History</h2> </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col cols="12" sm="10">
        <v-card>
          <v-data-table
            v-bind:headers="headers"
            :items="charges"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.updatedAt="{ item }">
              {{ updatedAt(item.charge) }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ item.all_fees.total | currency }}
            </template>
            <template v-slot:item.status="{ item }">
              {{ status(item.charge) }}
            </template>
            <template v-slot:item.last4="{ item }">
              {{ last4(item.charge) }}
            </template>
            <template v-slot:item.orderId="{ item }">
              <router-link :to="'/order/view/' + item.orderId">{{
                item.orderId
              }}</router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-btn class="primary white--text" @click="$router.go(-1)" icon>
      <v-icon>chevron_left</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      headers: [
        { text: 'Date', value: 'updatedAt' },
        { text: 'Amount', value: 'amount' },

        { text: 'Last4 ', value: 'last4' },
        { text: 'Status', value: 'status' },
        { text: 'Order', value: 'orderId' }
      ]
    }
  },
  mounted() {
    // already checked in parent
    this.$store.dispatch('checkAccount', { charges: true })
    this.$store.dispatch('getCharges')
  },
  computed: {
    charges() {
      let charges = this.$store.getters.charges
      let list = []
      for (let charge in charges) {
        list.push(charges[charge])
      }
      return list
    }
  },
  methods: {
    updatedAt(charge) {
      try {
        const c = JSON.parse(charge)
        return c.created ? moment.unix(c.created).format('L') : null
      } catch (error) {
        console.error(error)
      }
    },
    status(charge) {
      try {
        const c = JSON.parse(charge)
        return c.status
      } catch (error) {
        console.error(error)
      }
    },

    last4(charge) {
      try {
        const c = JSON.parse(charge)
        console.log(c)
        return c.payment_method_details
          ? c.payment_method_details.card.last4
          : null
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
