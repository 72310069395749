<template>
  <v-container v-if="profile">
    <div v-if="stripeCustomerInitialized">
      <payment-history />
    </div>

    <profile-status></profile-status>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileStatus from 'fod-core/src/components/profile/ProfileStatus.vue'

import { Card, createToken } from 'vue-stripe-elements-plus'
import PaymentHistory from './PaymentHistory'
// @vue/component
export default {
  components: { Card, PaymentHistory, ProfileStatus },

  data() {
    return {
      stripe: process.env.VUE_APP_STRIPE_CLIENT_TOKEN,
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      postalCode: false,
      submitted: false,
      status: '',
      response: '',

      stripeOptions: {
        // you can configure that cc element. I liked the default, but you can
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      acceptedAccountTerms: false,
      currentUser: null,
      newCreditCard: {
        number: '4242424242424242',
        cvc: '111',
        exp_month: 1,
        exp_year: 2020,
        address_zip: '00000'
      },
      charges: {},
      newCharge: {
        source: null,
        amount: 2000
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile',
      newUser: 'newUser',
      isChef: 'isChef'
    }),
    sources() {
      return this.$store.getters.sources
    },

    stripeCustomerInitialized() {
      console.log('ss', this.$store.getters.stripeCustomerInitialized)
      return this.$store.getters.stripeCustomerInitialized
    }
    // stripeKey() {
    //   return process.env.VUE_APP_STRIPE_CLIENT_TOKEN
    // }
  },
  mounted() {
    this.$store.dispatch('checkAccount')
     this.includeStripe('js.stripe.com/v3/', function(){
        this.configureStripe();
    }.bind(this) );
  },
  created() {
    this.$store.dispatch('touchPayment')
  },

  methods: {
    refresh() {
      this.$store.dispatch('checkAccount', { charges: true })
      this.$store.dispatch('getCharges')
    },
    activateAccount() {
      this.$store.dispatch('activateAccount')
    },
    deleteSrc(id) {
      console.log('Deleting source', id)
      this.$store.dispatch('deleteSource', id)
    },
    setDefaultSrc(id) {
      console.log('setting default source', id)
    },
    pay() {
      console.log('Paying')
      createToken()
        .then(data => {
          this.submitted = true
          console.log(data.token) //this is a token we would use for the stripeToken below
          this.$store.dispatch('addSource', data.token)
        })
        .catch(error => {
          console.log(error)
        })
    },
    submitNewCreditCard: function() {
      createToken().then({
        number: this.newCreditCard.number,
        cvc: this.newCreditCard.cvc,
        exp_month: this.newCreditCard.exp_month,
        exp_year: this.newCreditCard.exp_year,
        address_zip: this.newCreditCard.address_zip
      }),
        (status, response) => {
          if (response.error) {
            this.newCreditCard.error = response.error.message
          } else {
            /*firebase.database().ref(`/stripe_customers/${this.currentUser.uid}/sources`).push({token: response.id}).then(() => {
                  this.newCreditCard = {
                    number: '',
                    cvc: '',
                    exp_month: 1,
                    exp_year: 2017,
                    address_zip: ''
                  };
                });*/
          }
        }
    },
    includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
  }
}
</script>
