var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h2',[_vm._v("Payment History")])])],1),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.charges,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.updatedAt(item.charge))+"\n          ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("currency")(item.all_fees.total))+"\n          ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.status(item.charge))+"\n          ")]}},{key:"item.last4",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.last4(item.charge))+"\n          ")]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/order/view/' + item.orderId}},[_vm._v(_vm._s(item.orderId))])]}}])})],1)],1)],1),_c('v-btn',{staticClass:"primary white--text",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("chevron_left")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }